<template>
  <div
    class="form-slider"
  >
    <div
      ref="slider"
      class="slider"
    />

    <div
      v-if="hideLabel !== true"
      class="label"
    >
      {{ localState }}
    </div>
  </div>
</template>

<script>
  import noUiSlider from 'nouislider';

  export default {
    name: 'FormSlider',
    model: {
      prop: 'value',
      event: 'change',
    },
    props: {
      value: {
        type: Number,
        default: null,
      },
      min: {
        type: Number,
        default: null,
      },
      max: {
        type: Number,
        default: null,
      },
      range: {
        type: Object,
        default: null,
      },
      hideLabel: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        noui: null,
        localState: null,
        isActive: false,
      };
    },
    watch: {
      value: {
        immediate: true,
        deep: true,
        handler(value) {
          this.localState = value;

          if (this.noui && this.isActive === false) {
            this.noui.set(this.localState);
          }
        },
      },
      min() {
        this.updateOptions();
      },
      max() {
        this.updateOptions();
      },
      range() {
        this.updateOptions();
      },
    },
    created() {
      if ((this.min === null && this.max === null) && this.range === null) {
        throw new Error('Either min and max must be provided or range. None given.');
      }
    },
    mounted() {
      this.noui = noUiSlider.create(this.$refs.slider, {
        start: this.value || 0,
        connect: 'lower',
        range: this.range || {
          min: [this.min],
          max: [this.max],
        },
      });

      this.noui.on('set', this.onChange.bind(this));
      this.noui.on('update', this.onUpdate.bind(this));
      this.noui.on('start', () => { this.isActive = true; });
      this.noui.on('end', () => { this.isActive = false; });
    },
    beforeDestroy() {
      if (this.noui) {
        this.noui.destroy();
      }
    },
    methods: {
      updateOptions() {
        this.noui.updateOptions({
          range: this.range || {
            min: [this.min],
            max: [this.max],
          },
        });
      },
      onUpdate(values, handle, unencoded) {
        this.$emit('update', this.localState = Math.round(unencoded[0]));
      },
      onChange(values, handle, unencoded) {
        this.$emit('change', Math.round(unencoded[0]));
      },
    },
  };
</script>

<style lang="scss">
  .form-slider {
    min-width: 200px;

    > .label {
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.23px;
      line-height: 1.14;
      margin-top: 1.5space;
      text-align: center;
    }
  }
</style>
